<template>
  <v-form ref="form" @submit.prevent="handleSave">
    <v-container class="white">
      <v-row>
        <v-col class="text-h6">
          {{ $trans("schedule_name") }}: {{ schedule.name }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <calendesk-information-message>
            {{ $trans("availability_schedule_assign_to_services_info") }}
          </calendesk-information-message>
        </v-col>
      </v-row>
      <v-row>
        <v-col style="min-height: 400px">
          <services-field
            v-model="selectedServiceIds"
            chips
            multiple
            deletable-chips
            return-ids
            keep-menu-open-on-select
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import ServicesField from "@/lib/calendesk-js-library/components/ServicesField.vue";

export default {
  components: { ServicesField, CalendeskInformationMessage },
  mixins: [sharedActions],
  data() {
    return {
      selectedServiceIds: null,
      initialSelectedServiceIds: null,
      isLoading: false,
      schedule: null,
    };
  },
  created() {
    if (this.getDialog.data && this.getDialog.data.services) {
      this.schedule = this.getDialog.data;
      this.selectedServiceIds = this.schedule.services.map(
        (service) => service.id
      );
      this.initialSelectedServiceIds = this.selectedServiceIds;
    }
  },
  methods: {
    ...mapActions({
      updateMultipleServices: "service/updateMultiple",
      refreshSchedulesList: "availabilitySchedule/refreshSchedulesList",
    }),
    async handleSave() {
      if (this.$refs.form.validate()) {
        try {
          this.isLoading = true;
          this.$emit("ctaButtonLoading", true);

          const { unassignServicesIds, assignServicesIds } =
            this.calculateEmployeeChanges();

          await this.unassignServices(unassignServicesIds);
          await this.assignServices(assignServicesIds);

          await this.refreshSchedulesList();

          successNotification("update_success");
        } catch (error) {
          errorNotification(null, error, false);
        } finally {
          this.isLoading = false;
          this.$emit("ctaButtonLoading", false);
          this.$emit("close");
        }
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },

    calculateEmployeeChanges() {
      const assignServicesIds = this.selectedServiceIds;
      const unassignServicesIds = this.initialSelectedServiceIds.filter(
        (id) => !assignServicesIds.includes(id)
      );

      return { unassignServicesIds, assignServicesIds };
    },

    async unassignServices(serviceIds) {
      if (serviceIds && serviceIds.length > 0) {
        await this.updateMultipleServices({
          service_ids: serviceIds,
          fields: {
            availability_schedule_id: null,
          },
        });
      }
    },

    async assignServices(serviceIds) {
      if (serviceIds && serviceIds.length > 0) {
        await this.updateMultipleServices({
          service_ids: serviceIds,
          fields: {
            availability_schedule_id: this.schedule.id,
          },
        });
      }
    },
  },
};
</script>
